import {
  AiAnswerStatus,
  GetBestQuestionMobileCardsQuery,
  GetBestTagsQuery,
  GetKeywordQuestionCardCursorPageQuery,
  GetMyAnswerCursorPageQuery,
  GetMyLikedQuestionCardCursorPageQuery,
  GetMyQuestionCardCursorPageMobileQuery,
  GetMyQuestionCardCursorPageQuery,
  GetNewestQuestionCardCursorPageQuery,
  GetNewsMyQuestionQuery,
  GetNewsQuestionQuery,
  QuestionAccessibleType,
  QuestionStatus,
} from '@/types/bff/gql-schema';
import {
  QnaBestQuestions,
  QnaBestTags,
  QnaMyQuestionAnswers,
  QnaNewestMyQuestion,
  QnaNewestQuestions,
  QnaQuestionMobileCard,
} from '@/types/qna';
import { absoluteDate, displayedAt, getQnaAnswerStatus, sortImageBlindTypeFormat } from '@/utils/format';

/**
 * service
 */

// 방금 올라온 질문 parser
const parseQnaNewest = (data: GetNewsQuestionQuery): QnaNewestQuestions => {
  return {
    questions: data.newestQuestionCardCursorPage.elements
      .filter(
        (question) =>
          question.question.aiAnswerStatus === AiAnswerStatus.Accepted ||
          question.question.aiAnswerStatus === AiAnswerStatus.Rejected ||
          question.question.aiAnswerStatus === AiAnswerStatus.None,
      )
      .map((element) => {
        return {
          ...element,
          question: {
            ...element.question,
            attachments: sortImageBlindTypeFormat(element.question.attachments),
          },
        };
      }),
  };
};

// 방금 올라온 질문 -> 내질문 parser
const parseQnaNewestMyQuestion = (data: GetNewsMyQuestionQuery): QnaNewestMyQuestion => {
  const recentData = data.myQuestionCardCursorPage.elements[0];
  if (
    recentData.question.aiAnswerStatus === AiAnswerStatus.Accepted ||
    recentData.question.aiAnswerStatus === AiAnswerStatus.Rejected ||
    recentData.question.aiAnswerStatus === AiAnswerStatus.None
  ) {
    return recentData;
  }
  return {} as QnaNewestMyQuestion;
};

const pareQnaBestTags = (tags: GetBestTagsQuery): QnaBestTags => {
  return tags;
};

// 베스트 질문 TOP5 parser
const parseQnaBestQuestions = (bestQuestions: GetBestQuestionMobileCardsQuery): QnaBestQuestions => {
  return {
    bestQuestionCards: bestQuestions.bestQuestionCards.map((question) => {
      return {
        ...question,
        question: {
          ...question.question,
          attachments: sortImageBlindTypeFormat(question.question.attachments),
        },
      };
    }),
  };
};

// 메인 홈 내 질문에 달긴 답글 parser
export const parseQnaMyQuestionAnswers = (data: GetMyAnswerCursorPageQuery): QnaMyQuestionAnswers => {
  return {
    answers: data.myAnswerCursorPage.elements.slice(0, 8),
    isMore: data.myAnswerCursorPage.elements.length > 8,
  };
};

// 실시간 질문 전체보기 parser
const parseQnaNewestQuestionCard = (data: GetNewestQuestionCardCursorPageQuery): Array<QnaQuestionMobileCard> => {
  return data.newestQuestionCardCursorPage.elements.map((element) => {
    return {
      ...element,
      question: {
        ...element.question,
        attachments: sortImageBlindTypeFormat(element.question.attachments),
      },
      questionCreatedAt: displayedAt(element.question.createdAt),
      answerCreatedAt: element.answer ? displayedAt(element.answer.createdAt) : null,
      answeredStatusText: getQnaAnswerStatus(element.answeredStatus),
    };
  });
};

// 검색 결과 질문 전체보기 parser
const parseQnaKeywordQuestionCard = (data: GetKeywordQuestionCardCursorPageQuery): Array<QnaQuestionMobileCard> => {
  return data.keywordSearchQuestionCardCursorPage.elements.map((element) => {
    return {
      ...element,
      question: {
        ...element.question,
        attachments: sortImageBlindTypeFormat(element.question.attachments),
      },
      questionCreatedAt: displayedAt(element.question.createdAt),
      answerCreatedAt: element.answer ? displayedAt(element.answer.createdAt) : null,
      answeredStatusText: getQnaAnswerStatus(element.answeredStatus),
    };
  });
};

// 마이페이지 내 질문 전체보기 parser
const parseQnaMyQuestionCard = (data: GetMyQuestionCardCursorPageMobileQuery): Array<QnaQuestionMobileCard> => {
  return data.myQuestionCardCursorPage.elements.map((element) => {
    return {
      ...element,
      paymentTotal: element.paymentTotal,
      question: {
        ...element.question,
        attachments: sortImageBlindTypeFormat(element.question.attachments),
      },
      questionCreatedAt: absoluteDate(element.question.createdAt),
      answerCreatedAt: element.answer ? displayedAt(element.answer.createdAt) : null,
      answeredStatusText: getQnaAnswerStatus(element.answeredStatus),
    };
  });
};

// 마이페이지 관심 질문 전체보기 parser
const parseQnaMyLikedQuestionCard = (data: GetMyLikedQuestionCardCursorPageQuery): Array<QnaQuestionMobileCard> => {
  return data.myLikedQuestionCardCursorPage.elements.map((element) => {
    return {
      ...element,
      question: {
        ...element.question,
        attachments: sortImageBlindTypeFormat(element.question.attachments),
      },
      questionCreatedAt: absoluteDate(element.question.createdAt),
      answerCreatedAt: element.answer ? displayedAt(element.answer.createdAt) : null,
      answeredStatusText: getQnaAnswerStatus(element.answeredStatus),
    };
  });
};

/**
 * service
 */

// 방금 올라온 질문 service
export const qnaNewestService = (data: GetNewsQuestionQuery): QnaNewestQuestions | undefined => {
  if (data.newestQuestionCardCursorPage.elements.length) {
    return parseQnaNewest(data);
  }
};

// 방금 올라온 질문 -> 내질문 service
export const qnaNewestMyQuestionService = (data: GetNewsMyQuestionQuery): QnaNewestMyQuestion => {
  if (data.myQuestionCardCursorPage.elements.length) {
    return parseQnaNewestMyQuestion(data);
  }
  return {} as QnaNewestMyQuestion;
};

// 자주 찾는 키워드 service
export const qnaBestTagsService = (data: GetBestTagsQuery): QnaBestTags | undefined => {
  if (data.bestTags.length) {
    return pareQnaBestTags(data);
  }
};

// // 베스트 질문 TOP5 service
export const qnaBestQuestionsService = (data: GetBestQuestionMobileCardsQuery): QnaBestQuestions | undefined => {
  if (data) {
    return parseQnaBestQuestions(data);
  }
};

// 메인 홈 내 질문에 달긴 답글 service
export const qnaMyQuestionAnswersService = (data: GetMyAnswerCursorPageQuery): QnaMyQuestionAnswers | undefined => {
  if (data.myAnswerCursorPage.elements.length) {
    return parseQnaMyQuestionAnswers(data);
  }
};

// 실시간 질문 전체보기 service
export const qnaNewestQuestionCardService = (
  data: Array<GetNewestQuestionCardCursorPageQuery>,
): Array<QnaQuestionMobileCard> => {
  if (data) {
    return parseQnaNewestQuestionCard({
      newestQuestionCardCursorPage: {
        elements: data.flatMap((page) => page.newestQuestionCardCursorPage.elements),
        pivot: data[data.length - 1].newestQuestionCardCursorPage.pivot,
      },
    });
  }
  return [];
};

// 검색 결과 질문 전체보기 service
export const qnaKeywordQuestionCardService = (
  data: Array<GetKeywordQuestionCardCursorPageQuery>,
): Array<QnaQuestionMobileCard> => {
  if (data) {
    return parseQnaKeywordQuestionCard({
      keywordSearchQuestionCardCursorPage: {
        elements: data.flatMap((page) => page.keywordSearchQuestionCardCursorPage.elements),
        pivot: data[data.length - 1].keywordSearchQuestionCardCursorPage.pivot,
      },
    });
  }
  return [];
};

// 마이페이지 내 질문 전체보기 service
export const qnaMyQuestionCardService = (
  data: Array<GetMyQuestionCardCursorPageQuery>,
): Array<QnaQuestionMobileCard> => {
  if (data) {
    return parseQnaMyQuestionCard({
      myQuestionCardCursorPage: {
        elements: data.flatMap((page) => page.myQuestionCardCursorPage.elements),
        pivot: data[data.length - 1].myQuestionCardCursorPage.pivot,
      },
    });
  }
  return [];
};

export const isQnaMyQuestionPrivate = (data?: QuestionAccessibleType | null) => {
  return data === QuestionAccessibleType.Me;
};

export const isQnaMyQuestionPaidFail = (data?: QuestionStatus | null) => {
  return data === QuestionStatus.PaymentFailed;
};

export const isQnaMyQuestionPaid = (data?: QuestionStatus | null) => {
  switch (data) {
    case QuestionStatus.PendingPayment:
      return true;
    case QuestionStatus.PaymentFailed:
      return true;
    case QuestionStatus.PremiumAnswered:
      return true;
    case QuestionStatus.PremiumAnswering:
      return true;
    case QuestionStatus.AwaitingPremiumAnswer:
      return true;
    case QuestionStatus.Refunded:
      return true;
    default:
      return false;
  }
};

// 마이페이지 관심 질문 전체보기 service
export const qnaMyLikedQuestionCardService = (
  data: Array<GetMyLikedQuestionCardCursorPageQuery>,
): Array<QnaQuestionMobileCard> => {
  if (data) {
    return parseQnaMyLikedQuestionCard({
      myLikedQuestionCardCursorPage: {
        elements: data.flatMap((page) => page.myLikedQuestionCardCursorPage.elements),
        pivot: data[data.length - 1].myLikedQuestionCardCursorPage.pivot,
      },
    });
  }
  return [];
};
