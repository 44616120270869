import { ReactNode } from 'react';

import { AlertModalProps } from '@/components/common/Modal/AlertModal';

export const MODAL_BUTTON_TYPE = {
  DEFAULT: 'default',
  BOLD: 'bold',
  PRIMARY: 'primary',
  DANGER: 'danger',
} as const;
export type MODAL_BUTTON_TYPE = typeof MODAL_BUTTON_TYPE[keyof typeof MODAL_BUTTON_TYPE];

export interface ModalButtonProps {
  type?: MODAL_BUTTON_TYPE;
  label?: ReactNode;
  labelColor?: string;
  onClick: () => void;
}

export interface BasicModalProps {
  index: number;
  body: ReactNode;
  onClickOverlay?: () => void;
}

export interface CustomBackgroundColorModalProps {
  index: number;
  body: ReactNode;
  backgroundColor: string;
  onClickOverlay?: () => void;
}

export type ModalProps =
  | Omit<BasicModalProps, 'index'>
  | Omit<AlertModalProps, 'index'>
  | Omit<CustomBackgroundColorModalProps, 'index'>;

export const MODAL_TYPE = {
  ALERT: 'ALERT',
  BASIC: 'BASIC',
  LOGIN: 'LOGIN',
  CUSTOM_BACKGROUND: 'CUSTOM_BACKGROUND',
} as const;
export type MODAL_TYPE = typeof MODAL_TYPE[keyof typeof MODAL_TYPE];

export interface ModalVo {
  index: number;
  type?: MODAL_TYPE;
  props: ModalProps;
}
