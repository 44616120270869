import dayjs from 'dayjs';
import { ElementType, ReactNode } from 'react';

import { CARD_ICON, CARD_ICON_V2, DEFAULT_CARD_ICON } from '@/constants/card';
import { TreatmentRequest } from '@/models/diagnosis';
import {
  AddressType,
  CombinedEligibilityTagType,
  TelemedicineEligibleGroupType,
  TelemedicineTargetType,
  UserProfileRelation,
} from '@/types/bff/gql-schema';
import { EDiagnosisContactType } from '@/types/diagnosis';

export enum DoctorMedicineType {
  Dentist = 'DENTIST',
  Doctor = 'DOCTOR',
  OrientalDoctor = 'ORIENTAL_DOCTOR',
  Unknown = 'UNKNOWN',
}

const getUserAddressTypeTitle = (addressType: AddressType) => {
  switch (addressType) {
    case AddressType.Home:
      return '집';
    case AddressType.Company:
      return '회사';
    case AddressType.Others:
      return '기타';
    default:
      return '기타';
  }
};

export enum UserAddressType {
  Home = 'HOME',
  Company = 'COMPANY',
  Others = 'OTHERS',
}

const getUserAddressType = (addressType: AddressType): UserAddressType => {
  switch (addressType) {
    case AddressType.Home:
      return UserAddressType.Home;
    case AddressType.Company:
      return UserAddressType.Company;
    case AddressType.Others:
      return UserAddressType.Others;
    default:
      return UserAddressType.Others;
  }
};

const getFullAddress = (address: {
  roadAddress: string | null;
  oldAddress: string;
  detailAddress: string;
  deliveryComment: string | null;
}) => {
  let fullAddress = (address.roadAddress || address.oldAddress) + `, ${address.detailAddress}`;

  if (address.deliveryComment) {
    fullAddress += ` (${address.deliveryComment})`;
  }

  return fullAddress;
};

export interface UserAddress {
  userAddressId: string;
  userId: string;
  alias: string;
  roadAddress: string | null;
  oldAddress: string;
  detailAddress: string;
  addressType: UserAddressType;
  addressTypeTitle: string;
  zipCode: string | null;
  deliveryComment: string | null;
  frontDoorPassword: string | null;
  isDefault: boolean;
  location: { latitude: number; longitude: number };
  fullAddress: string;
}

export class UserAddressesInfo {
  private MAX_ADDRESS_COUNT = 5;
  private ADDRESS_HIGHORITY: Record<string, number> = {
    home: 0,
    company: 1,
    others: 2,
  };

  constructor(
    private _addresses: Array<{
      userAddressId: string;
      userId: string;
      alias: string;
      roadAddress: string | null;
      oldAddress: string;
      detailAddress: string;
      addressType: AddressType;
      zipCode: string | null;
      deliveryComment: string | null;
      frontDoorPassword: string | null;
      isDefault: boolean;
      location: { latitude: number; longitude: number };
    }>,
  ) {}

  private get addresses(): Array<UserAddress> {
    return this._addresses.map((userAddress) => {
      return {
        ...userAddress,
        addressTypeTitle: getUserAddressTypeTitle(userAddress.addressType),
        addressType: getUserAddressType(userAddress.addressType),
        fullAddress: getFullAddress(userAddress),
      };
    });
  }

  get isRegisterable(): boolean {
    return this._addresses.length < this.MAX_ADDRESS_COUNT;
  }

  get sortedAddresses(): Array<UserAddress> {
    const newAddresses = [...this.addresses];
    return (
      newAddresses.sort(
        (prev, curr) => this.ADDRESS_HIGHORITY[prev.addressType] - this.ADDRESS_HIGHORITY[curr.addressType],
      ) ?? []
    );
  }

  findAddress(addressId?: string): UserAddress | null {
    return this.addresses.find((address) => address.userAddressId === addressId) || null;
  }

  hasAddresses(): boolean {
    return this.addresses.length > 0;
  }

  hasAddressFromAddressType(addressType: UserAddressType): boolean {
    return this.addresses.some((address) => address.addressType === addressType);
  }

  findHomeAddress(): UserAddress | null {
    return this.addresses.find((address) => address.addressType === UserAddressType.Home) || null;
  }

  findCompanyAddress(): UserAddress | null {
    return this.addresses.find((address) => address.addressType === UserAddressType.Company) || null;
  }

  getOtherAddresses(): Array<UserAddress> {
    return this.addresses.filter((address) => address.addressType === UserAddressType.Others);
  }
}
export enum UserTelemedicineEligibleGroupType {
  BACKWOODS_RESIDENT = 'BACKWOODS_RESIDENT',
  MEDICAL_VULNERABLE_AREA_RESIDENT = 'MEDICAL_VULNERABLE_AREA_RESIDENT',
  LONG_TERM_CARE_CLASSIFIER = 'LONG_TERM_CARE_CLASSIFIER',
  REGISTERED_DISABLED = 'REGISTERED_DISABLED',
  INFECTIOUS_DISEASE_CASE = 'INFECTIOUS_DISEASE_CASE',
  UNDER_18_CHILD = 'UNDER_18_CHILD',
  VISITED_PATIENT = 'VISITED_PATIENT',
  OTHERS = 'OTHERS',
}
export enum UserProfileRelationType {
  Children = 'CHILDREN',
  Me = 'ME',
  Parents = 'PARENTS',
  Spouse = 'SPOUSE',
}

interface UserProfileInfo {
  birthDate: string;
  targetId: string;
  name: string;
  relation: UserProfileRelation;
  telemedicineTargetType: TelemedicineTargetType | null;
  telemedicineEligibleGroupType: TelemedicineEligibleGroupType | null;
  eligibilityTags: Array<{
    type: CombinedEligibilityTagType;
    message: string;
  }>;
  followingResidentFirstNumber: number;
  phone: string | null;
}

export class UserProfile {
  constructor(private _profile: UserProfileInfo) {}

  get displayedBirthDate(): string {
    return dayjs(this._profile.birthDate).format('YYMMDD');
  }

  get birthDate(): string {
    return this._profile.birthDate;
  }

  get targetId(): string {
    return this._profile.targetId;
  }

  get name(): string {
    return this._profile.name;
  }

  get phone(): string | null {
    return this._profile.phone;
  }

  get followingResidentFirstNumber(): number {
    return this._profile.followingResidentFirstNumber;
  }

  get relation(): UserProfileRelationType {
    switch (this._profile.relation) {
      case UserProfileRelation.Children:
        return UserProfileRelationType.Children;
      case UserProfileRelation.Me:
        return UserProfileRelationType.Me;
      case UserProfileRelation.Parents:
        return UserProfileRelationType.Parents;
      case UserProfileRelation.Spouse:
        return UserProfileRelationType.Spouse;
      default:
        return UserProfileRelationType.Me;
    }
  }

  get telemedicineTargetType(): TelemedicineTargetType | null {
    return this._profile.telemedicineTargetType;
  }
  get telemedicineEligibleGroupType(): TelemedicineEligibleGroupType | null {
    return this._profile.telemedicineEligibleGroupType;
  }

  get userTelemedicineEligibleGroupType(): UserTelemedicineEligibleGroupType | null {
    switch (this._profile.telemedicineEligibleGroupType) {
      case TelemedicineEligibleGroupType.BackwoodsResident:
        return UserTelemedicineEligibleGroupType.BACKWOODS_RESIDENT;
      case TelemedicineEligibleGroupType.InfectiousDiseaseCase:
        return UserTelemedicineEligibleGroupType.INFECTIOUS_DISEASE_CASE;
      case TelemedicineEligibleGroupType.LongTermCareClassifier:
        return UserTelemedicineEligibleGroupType.LONG_TERM_CARE_CLASSIFIER;
      case TelemedicineEligibleGroupType.RegisteredDisabled:
        return UserTelemedicineEligibleGroupType.REGISTERED_DISABLED;
      case TelemedicineEligibleGroupType.MedicalVulnerableAreaResident:
        return UserTelemedicineEligibleGroupType.MEDICAL_VULNERABLE_AREA_RESIDENT;
      case TelemedicineEligibleGroupType.Under_18Child:
        return UserTelemedicineEligibleGroupType.UNDER_18_CHILD;
      case TelemedicineEligibleGroupType.VisitedPatient:
        return UserTelemedicineEligibleGroupType.VISITED_PATIENT;
      case TelemedicineEligibleGroupType.Others:
        return UserTelemedicineEligibleGroupType.OTHERS;
      default:
        return null;
    }
  }

  get eligibilityTags(): Array<{
    type: CombinedEligibilityTagType;
    message: string;
  }> {
    return this._profile.eligibilityTags;
  }

  get relationDisplayName() {
    switch (this.relation) {
      case UserProfileRelationType.Me:
        return '본인';
      case UserProfileRelationType.Parents:
        return '부모님';
      case UserProfileRelationType.Spouse:
        return '배우자';
      case UserProfileRelationType.Children:
        return '자녀';
      default:
        return '';
    }
  }
}

export class UserProfiles {
  constructor(private _profiles: Array<UserProfileInfo>) {}

  get profiles(): Array<UserProfile> {
    return this._profiles.map((profile) => {
      return new UserProfile(profile);
    });
  }

  hasUserProfiles(): boolean {
    return this._profiles.length > 0;
  }

  getUserProfileByTargetId(targetId: string): UserProfile | null {
    return this.profiles.find((profile) => profile.targetId === targetId) || null;
  }
}

export interface InstallmentSelectItem {
  installmentMonth: number;
  text: string;
}

const INSTALLMENT_SELECT_LIST: InstallmentSelectItem[] = [
  { installmentMonth: 0, text: '일시불' },
  { installmentMonth: 2, text: '2개월' },
  { installmentMonth: 3, text: '3개월' },
  { installmentMonth: 4, text: '4개월' },
  { installmentMonth: 5, text: '5개월' },
  { installmentMonth: 6, text: '6개월' },
  { installmentMonth: 7, text: '7개월' },
  { installmentMonth: 8, text: '8개월' },
  { installmentMonth: 9, text: '9개월' },
  { installmentMonth: 10, text: '10개월' },
  { installmentMonth: 11, text: '11개월' },
  { installmentMonth: 12, text: '12개월' },
];

interface DisplayedCardCompany {
  icon: ReactNode;
  cardName: string;
  bgColor: string;
  textColor: string;
}

export interface MatchCardCompany {
  icon: ReactNode;
  label: string;
  match: string[];
  bgColor: string;
  textColor: string;
}

export enum CardPayCompany {
  Inisis = 'INISIS',
  Kakaopay = 'KAKAOPAY',
  Payple = 'PAYPLE',
  TossPg = 'TOSS_PG',
}

export interface UserPayment {
  canInstallment: boolean;
  cardName: string;
  cardNumber: string;
  displayedCardNumber: string;
  id: string;
  isApproved: boolean;
  isDefault: boolean;
  payCompany: CardPayCompany;
  displayedCardCompany: DisplayedCardCompany;
  CardIcon: ElementType;
}

export class UserPaymentsInfo {
  constructor(
    private _userPayments: Array<{
      canInstallment: boolean;
      cardName: string;
      cardNumber: string;
      id: string;
      isApproved: boolean;
      isDefault: boolean;
      payCompany: CardPayCompany;
    }>,
  ) {}

  private getDisplayedCardCompany = (cardName: string): DisplayedCardCompany => {
    const matchingIcon = CARD_ICON_V2.find(
      (card) => card.match.find((it) => it === cardName) || card.match.find((it) => it === cardName.substring(0, 2)),
    );
    if (!matchingIcon) {
      const { icon, bgColor, textColor } = DEFAULT_CARD_ICON;
      return { icon, cardName: cardName, bgColor, textColor };
    }
    const { icon, label, bgColor, textColor } = matchingIcon;
    return { icon, cardName: label, bgColor, textColor };
  };

  private displayCardNumber(cardNumber: string): string {
    const regexPattern = /(.{4})(?=.)/g;
    const replacement = '$1-';

    return cardNumber.replace(regexPattern, replacement);
  }

  private getCardIcon(cardName: string) {
    const matchingCardIcon = CARD_ICON.find(
      (cardIcon) =>
        cardIcon.match.find((it) => it === cardName) || cardIcon.match.find((it) => it === cardName.substring(0, 2)),
    );

    return matchingCardIcon?.cardIcon || CARD_ICON[CARD_ICON.length - 1].cardIcon;
  }

  get payments(): Array<UserPayment> {
    return this._userPayments.map((payment) => {
      return {
        canInstallment: payment.canInstallment,
        cardName: payment.cardName,
        cardNumber: payment.cardNumber,
        displayedCardNumber: this.displayCardNumber(payment.cardNumber),
        id: payment.id,
        isApproved: payment.isApproved,
        isDefault: payment.isDefault,
        payCompany: payment.payCompany,
        displayedCardCompany: this.getDisplayedCardCompany(payment.cardName),
        CardIcon: this.getCardIcon(payment.cardName),
      };
    });
  }

  get nonKakaopayPayments(): Array<UserPayment> {
    return this.payments.filter((payment) => payment.payCompany !== CardPayCompany.Kakaopay);
  }

  get kakaopayPayments(): Array<UserPayment> {
    return this.payments.filter((payment) => payment.payCompany === CardPayCompany.Kakaopay);
  }

  get installments(): Array<InstallmentSelectItem> {
    return INSTALLMENT_SELECT_LIST;
  }

  findSelectedInstallment(installmentMonth: number | undefined): InstallmentSelectItem | null {
    if (installmentMonth === undefined) return null;

    return this.installments.find((installment) => installment.installmentMonth === installmentMonth) || null;
  }

  findDefaultPayment(): UserPayment | null {
    return this.payments.find((payment) => payment.isDefault) || null;
  }

  get hasKakaoPayments(): boolean {
    return this.kakaopayPayments.length > 0;
  }

  get hasNonKakaoPayments(): boolean {
    return this.nonKakaopayPayments.length > 0;
  }

  getCanInstallmentByTreatmentRequest(treatmentRequest: TreatmentRequest): boolean {
    const enableInstallmentTreatmentRequest =
      treatmentRequest.isOrientalNonF2FTreatmentRequest() || treatmentRequest.isDietF2FTreatmentRequest();

    return enableInstallmentTreatmentRequest
      ? this.payments.find((payment) => payment.id === treatmentRequest.form.paymentId)?.canInstallment ?? false
      : false;
  }

  getValidationInstallmentByTreatmentRequest(treatmentRequest: TreatmentRequest): boolean {
    if (!this.getCanInstallmentByTreatmentRequest(treatmentRequest)) {
      return true;
    }

    return treatmentRequest.form.installmentMonth !== undefined;
  }
}

export interface TreatmentRequestAgreement {
  required: boolean;
  text: string;
  selected: boolean;
  outLink?: string;
  contactTypeList: Array<EDiagnosisContactType>;
}

export const treatmentRequestAgreements: Array<TreatmentRequestAgreement> = [
  {
    required: true,
    text: '개인정보 수집 및 이용 동의',
    selected: false,
    outLink: 'https://company.doctornow.co.kr/board_diagnosis/?action=readpost&post_id=3494&bbspaged=1',
    contactTypeList: [EDiagnosisContactType.NonF2F],
  },
  {
    required: true,
    text: '건강정보 수집 및 이용 동의',
    selected: false,
    outLink: 'https://company.doctornow.co.kr/board_diagnosis/?action=readpost&post_id=3495&bbspaged=1',
    contactTypeList: [EDiagnosisContactType.F2F, EDiagnosisContactType.NonF2F],
  },
  {
    required: true,
    text: '제3자 제공 동의',
    selected: false,
    outLink: 'https://company.doctornow.co.kr/board_diagnosis/?action=readpost&post_id=3496&bbspaged=1',
    contactTypeList: [EDiagnosisContactType.NonF2F],
  },
  {
    required: true,
    text: '중요 안내사항에 대한 동의',
    selected: false,
    outLink: 'https://company.doctornow.co.kr/board/?action=readpost&post_id=1521',
    contactTypeList: [EDiagnosisContactType.F2F, EDiagnosisContactType.NonF2F],
  },
  {
    required: true,
    text: '동일 성분 대체 조제 동의',
    selected: false,
    contactTypeList: [EDiagnosisContactType.F2F, EDiagnosisContactType.NonF2F],
  },
  {
    required: true,
    text: '진료비 자동 결제 동의',
    selected: false,
    contactTypeList: [EDiagnosisContactType.F2F, EDiagnosisContactType.NonF2F],
  },
  {
    required: true,
    text: '약제비 미결제 시 자동 결제 동의',
    selected: false,
    contactTypeList: [EDiagnosisContactType.F2F, EDiagnosisContactType.NonF2F],
  },
];

export interface InviterInfoResponse {
  code: string;
  invitationCount: number;
  inviterReceivedPoints: number;
  inviterRewardAmount: number;
  inviteeRewardAmount: number;
  inviterReceivableTotalPoints: number;
}

export interface InviterInfoData {
  invitationCode: string;
  canReceiveReward: boolean;
  inviterRewardAmount: number;
  inviteeRewardAmount: number;
  inviterReceivableTotalPoints: number;
  maxInvitationCount: number;
}

export class InviterInfoModel {
  private readonly invitationCode: string;
  private readonly canReceiveReward: boolean;
  private readonly inviterRewardAmount: number;
  private readonly inviteeRewardAmount: number;
  private readonly maxInvitationCount: number;
  private readonly inviterReceivableTotalPoints: number;

  constructor(
    invitationCode: string,
    canReceiveReward: boolean,
    inviterRewardAmount: number,
    inviteeRewardAmount: number,
    inviterReceivableTotalPoints: number,
  ) {
    this.invitationCode = invitationCode;
    this.inviterRewardAmount = inviterRewardAmount;
    this.inviteeRewardAmount = inviteeRewardAmount;
    this.maxInvitationCount = Math.floor(inviterReceivableTotalPoints / inviterRewardAmount);
    this.canReceiveReward = canReceiveReward;
    this.inviterReceivableTotalPoints = inviterReceivableTotalPoints;
  }

  getData(): InviterInfoData {
    return {
      invitationCode: this.invitationCode,
      canReceiveReward: this.canReceiveReward,
      inviterRewardAmount: this.inviterRewardAmount,
      inviteeRewardAmount: this.inviteeRewardAmount,
      inviterReceivableTotalPoints: this.inviterReceivableTotalPoints,
      maxInvitationCount: this.maxInvitationCount,
    };
  }

  static of(response: InviterInfoResponse): InviterInfoModel {
    return new InviterInfoModel(
      response.code,
      response.inviterReceivedPoints < response.inviterReceivableTotalPoints,
      response.inviterRewardAmount,
      response.inviteeRewardAmount,
      response.inviterReceivableTotalPoints,
    );
  }

  static initialOf(invitationCode: string): InviterInfoModel {
    return new InviterInfoModel(invitationCode, false, 0, 0, 0);
  }
}
