/* Ref. https://github.com/GriffinJohnston/uiball-loaders/blob/main/src/components/Ring.js */
import { SpinnerCircle, SpinnerContainer } from './styles';

interface Props {
  color?: string;
  size?: number;
}

const Spinner = ({ color, size }: Props) => {
  return (
    <SpinnerContainer viewBox="25 25 50 50" strokeWidth="5" size={size}>
      <SpinnerCircle cx="50" cy="50" r="20" color={color} />
    </SpinnerContainer>
  );
};

export default Spinner;
