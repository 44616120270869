import { FunctionComponent, ReactNode, useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { modalState } from '@/recoil/atoms/common/modal';
import { BasicModalProps, CustomBackgroundColorModalProps, MODAL_TYPE } from '@/types/modal';

import AlertModal, { AlertModalProps } from './AlertModal';
import BasicModal from './BasicModal';
import CustomBackgroundColorModal from './CustomBackgroundColorModal';

export interface ModalButtonProps {
  label?: ReactNode;
  labelColor?: string;
  onClick: () => void;
}

const Modal: FunctionComponent = () => {
  const modals = useRecoilValue(modalState);

  useEffect(() => {
    if (modals.length > 0) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [modals]);

  return (
    <>
      {modals.map(({ index, type, props }) => {
        switch (type) {
          case MODAL_TYPE.BASIC:
          default:
            return <BasicModal {...(props as BasicModalProps)} index={index} key={`customModal-${index}`} />;
          case MODAL_TYPE.ALERT:
            return <AlertModal {...(props as AlertModalProps)} index={index} key={`alertModal-${index}`} />;

          case MODAL_TYPE.CUSTOM_BACKGROUND:
            return (
              <CustomBackgroundColorModal
                {...(props as CustomBackgroundColorModalProps)}
                index={index}
                key={`customBackgroundModal-${index}`}
              />
            );
        }
      })}
    </>
  );
};

export default Modal;
