import { HtmlHTMLAttributes } from 'react';
import styled from 'styled-components';

import { MaterialIconName } from '@/types/common';

interface MaterialIconProps extends HtmlHTMLAttributes<HTMLDivElement> {
  name: MaterialIconName;
  size?: number;
  color?: string;
  filled?: boolean;
  rotate?: number;
  onClick?: () => void;
}

/**
 *
 * @param name string
 * @param size number (optional)
 * @param color number (optional)
 * @param filled number (optional)
 * @param rotate number (optional)
 * @param onClick () => void (optional)
 * @default
 * size 24px
 * color theme.color.gray900
 * filled false
 *
 * @example
 * <MaterialIcon name={'thumb_down'} size={32} filled />
 *
 */

const MaterialIcon = ({ name, size, color, filled, onClick, rotate = 0, className }: MaterialIconProps) => {
  return (
    <Wrapper width={size} rotate={rotate} onClick={onClick}>
      <Icon className={`material-symbols-rounded ${className}`} size={size} color={color} filled={filled}>
        {name}
      </Icon>
    </Wrapper>
  );
};

export default MaterialIcon;

const Wrapper = styled.div<{ width?: number; rotate?: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => props.width && `width: ${props.width}px;`}
  ${(props) => props.rotate && `rotate: ${props.rotate}deg;`}
`;
const Icon = styled.span<{
  size?: number;
  color?: string;
  filled?: boolean;
}>`
  ${(props) => props.size && `font-size: ${props.size}px;`}
  ${(props) => props.color && `color: ${props.color};`}
  ${(props) => props.filled && `font-variation-settings: 'FILL' ${props.filled ? 1 : 0};`}
  overflow: hidden;
`;
