export enum FEATURE_FLAG {}

export const SIX_HOURS_IN_MILLISECONDS = 1000 * 60 * 60 * 6;

export const APP_PUBLIC_DOWNLOAD_URL = 'https://abr.ge/@doctornow';
export const APP_TRACKING_CHANNEL = 'web_organic';
export const APP_GENERAL_TRACKING_PARAMS =
  'og_tag_id=138187085&routing_short_id=d816rm&tracking_template_id=2b94e9766fea0e112f2b11b7410088c4&ad_type=click';

export const APP_STORE_PAGE_LINK_TRACKING_PARAMS =
  'og_tag_id=138187710&routing_short_id=pzm4azo&tracking_template_id=a82e9a369aeb09d88afb5812cf2376b6&ad_type=click';

