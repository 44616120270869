import dynamic from 'next/dynamic';
import React from 'react';

import { Client } from '@/types/app';

const InnerMobileWebLayout = dynamic(() => import('@/components/layouts/InnerMobileWebLayout'), { ssr: true });
const InnerWebLayout = dynamic(() => import('@/components/layouts/InnerWebLayout'), { ssr: true });
const InnerWebViewLayout = dynamic(() => import('@/components/layouts/InnerWebViewLayout'), { ssr: true });

interface Props {
  children: React.ReactNode;
  userAgent: string;
  client: Client;
  customGlobalSideBar?: React.ReactNode;
}

const DefaultLayout = ({ children, client, userAgent, customGlobalSideBar }: Props) => {
  if (client.isWebView) {
    return <InnerWebViewLayout>{children}</InnerWebViewLayout>;
  }

  if (client.isMobile) {
    return <InnerMobileWebLayout userAgent={userAgent}>{children}</InnerMobileWebLayout>;
  }

  return (
    <InnerWebLayout userAgent={userAgent} customGlobalSideBar={customGlobalSideBar}>
      {children}
    </InnerWebLayout>
  );
};

export default DefaultLayout;
