import Router from 'next/router';

interface UpdateSearchParams {
  key: string;
  value: string | number | boolean;
}

const searchParamsToObject = (searchParams: URLSearchParams) => {
  return Object.fromEntries(searchParams);
};

const replaceQueue: (() => Promise<void>)[] = [];
let isProcessingQueue = false;

const processQueue = async () => {
  if (isProcessingQueue) return;
  isProcessingQueue = true;

  while (replaceQueue.length > 0) {
    const replaceFn = replaceQueue.shift();
    if (replaceFn) {
      await replaceFn();
    }
  }

  isProcessingQueue = false;
};

const _updateSearchParams = async (params: UpdateSearchParams[]) => {
  if (typeof window !== 'undefined' && window.location && 'URLSearchParams' in window) {
    const searchParams = new URLSearchParams(window.location.search);
    params.forEach(({ key, value }) => {
      if (value && value.toString()) {
        searchParams.set(key, value.toString());
      } else {
        searchParams.delete(key);
      }
    });
    const parsedSearchParams = searchParamsToObject(searchParams);
    await Router.replace(
      {
        pathname: '',
        query: parsedSearchParams,
      },
      undefined,
      { shallow: true }
    );
  }
};

export const updateSearchParams = async (params: UpdateSearchParams[]) => {
  replaceQueue.push(async () => {
    await _updateSearchParams(params);
  });
  processQueue();
};

export const deleteQueryString = (querystringKey: string) => {
  if (typeof window !== 'undefined' && window.location) {
    delete Router.query[querystringKey];

    Router.replace(
      {
        pathname: '',
        query: Router.query,
      },
      undefined,
      {
        shallow: true,
      }
    );
  }
};

export const stringifyArrayToQueryString = (queryArray: string[]) => {
  return queryArray.map(Number).join(',');
};
export const stringifyToArray = (queryArray: string[]) => {
  return queryArray.join(',');
};

export const parseQueryStringToArray = (queryString?: string): Array<string> => {
  return queryString ? queryString.split(',') : [];
};

export const addQueryString = (queries?: Record<string, any>, prefix = '?') => {
  if (!queries) {
    return '';
  }

  const queryList = Object.entries(queries).reduce((acc, [key, value]) => {
    if (value) {
      acc.push(`${key}=${value.toString()}`);
    }
    return acc;
  }, [] as string[]);

  if (queryList.length === 0) {
    return '';
  }

  return `${prefix}${queryList.join('&')}`;
};

export const getUrlPathWithoutQueryString = (urlPath: string) => {
  return urlPath.split('?')[0];
};
