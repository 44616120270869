import airbridge from 'airbridge-web-sdk-loader';
import { URLQueryMappingOption } from 'airbridge-web-sdk-loader/build/airbridge_api/init';

import {
  APP_GENERAL_TRACKING_PARAMS,
  APP_PUBLIC_DOWNLOAD_URL,
  APP_STORE_PAGE_LINK_TRACKING_PARAMS,
  APP_TRACKING_CHANNEL,
} from '@/constants/common';
import { getEnv } from '@/constants/env';
import { infoReport } from '@/utils/report';

import { getErrorObject } from './error';

class Airbridge {
  static initialize() {
    try {
      airbridge.init({
        app: 'doctornow',
        webToken: 'cce91b6cf16e41d59a34f9a1bddda0c1',
        useProtectedAttributionWindow: true,
        protectedAttributionWindowInMinutes: 30,
        utmParsing: true,
      });
    } catch (error) {
      infoReport('Airbridge:initialize > error', { error }, getErrorObject(error));
    }
  }
  static trackEvent(eventName: string, eventParams?: Record<string, unknown>) {
    try {
      switch (eventName) {
        case 'page_view':
          airbridge.events.send(eventName, {
            customAttributes: {
              ...eventParams,
              env: getEnv(),
            },
            label: String(eventParams?.page_name),
          });
          break;
        case 'complete_subscription_payment':
          airbridge.events.send(eventName, {
            customAttributes: {
              ...eventParams,
              env: getEnv(),
            },
            value: Number(eventParams?.amountToPay),
          });
          break;
        default:
          airbridge.events.send(eventName, {
            customAttributes: {
              ...eventParams,
              env: getEnv(),
            },
          });
          break;
      }
    } catch (error) {
      infoReport(
        'Airbridge:trackEvent > error',
        {
          error,
          eventName,
          eventParams,
        },
        getErrorObject(error),
      );
    }
  }
  static setUserProperties(userProperties: Record<string, unknown>) {
    try {
      airbridge.setUserAttributes(userProperties);
    } catch (error) {
      infoReport(
        'Airbridge:setUserProperties > error',
        {
          error,
          userProperties,
        },
        getErrorObject(error),
      );
    }
  }

  static setUserId(userId: string) {
    try {
      airbridge.setUserId(userId);
    } catch (error) {
      infoReport('Airbridge:setUserId > error', { error, userId }, getErrorObject(error));
    }
  }

  static setDeepLink({
    buttonID,
    deeplinkUrl,
    ctaParams,
  }: {
    buttonID: string;
    deeplinkUrl: string;
    ctaParams?: {
      cta_param_1: string;
      cta_param_2: string;
      cta_param_3: string;
    };
  }) {
    try {
      airbridge.setDeeplinks({
        buttonID: buttonID,
        deeplinks: {
          ios: deeplinkUrl,
          android: deeplinkUrl,
          desktop: deeplinkUrl,
        },
        fallbacks: {
          ios: 'itunes-appstore',
          android: 'google-play',
        },
        ctaParams: ctaParams,
        redirect: true,
        desktopPopUp: true,
      });
    } catch (error) {
      infoReport('Airbridge:setDeepLink > error', { error }, getErrorObject(error));
    }
  }

  static setDynamicLink(param: URLQueryMappingOption, isNeedStorePageLink = false) {
    const params = JSON.parse(JSON.stringify(param));
    const trackingParams = isNeedStorePageLink ? APP_STORE_PAGE_LINK_TRACKING_PARAMS : APP_GENERAL_TRACKING_PARAMS;
    const url = `${APP_PUBLIC_DOWNLOAD_URL}/${APP_TRACKING_CHANNEL}?${new URLSearchParams(
      params,
    ).toString()}&${trackingParams}`;
    window.location.href = url;
  }
}

export default Airbridge;
