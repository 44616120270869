import {
  AnalyticsSettings,
  logEvent as firebaseLogEvent,
  setUserId as firebaseSetUserId,
  setUserProperties as firebaseSetUserProperties,
  initializeAnalytics,
  setDefaultEventParameters,
} from 'firebase/analytics';
import { getApps, initializeApp } from 'firebase/app';
import { Messaging, Unsubscribe } from 'firebase/messaging';

import { getEnv } from '@/constants/env';
import Airbridge from '@/utils/airbridge';
import { infoReport } from '@/utils/report';

import { getErrorObject } from './error';
import { isMobileWebByUserAgent, isWebViewByUserAgent } from './mobile/RNPlugins';

enum SERVICE_PLATFORM {
  WEB = 'web',
  USER_WEB = 'user_web',
}

enum SERVICE_UA_CATEGORY {
  DEVICE = 'device',
  BROWSER = 'browser',
}

enum DEVICE_PLATFORM {
  PC_WEB = 'pc_web',
  MOBILE_WEB = 'mobile_web',
  WEB_VIEW = 'web_view',
}

class Firebase {
  private static _analytics: Messaging | undefined;
  private static _analyticsLegacy: Messaging | undefined;
  private static _unsubscribe: Unsubscribe | undefined;
  public static token: string | undefined;

  static hasInitializedApp() {
    try {
      return getApps().length > 0;
    } catch (error) {
      infoReport('Firebase:hasInitializedApp > error', { error }, getErrorObject(error));
      return false;
    }
  }

  static initApp(userAgent: string) {
    try {
      if (this.hasInitializedApp()) {
        return;
      }
      const doctornowApp = initializeApp(
        {
          apiKey: 'AIzaSyB6aWEDORByEd5BND_Y2lTPmSK0NXG4GeA',
          authDomain: 'baedalyakgook.firebaseapp.com',
          databaseURL: 'https://baedalyakgook.firebaseio.com',
          projectId: 'baedalyakgook',
          storageBucket: 'baedalyakgook.appspot.com',
          messagingSenderId: '681556968865',
          appId: '1:681556968865:web:e85e45adcbaded119df7e0',
          measurementId: 'G-7VH09E540E',
        },
        'doctornow',
      );
      const webApp = initializeApp(
        {
          apiKey: 'AIzaSyC1Te_JUAD_peZJg-8gWAVIowC3Qv9VRBA',
          authDomain: 'doctornow-web.firebaseapp.com',
          databaseURL: 'https://baedalyakgook.firebaseio.com',
          projectId: 'doctornow-web',
          storageBucket: 'doctornow-web.appspot.com',
          messagingSenderId: '512351040521',
          appId: '1:512351040521:web:cbcce24e6e7619fd9d74d8',
          measurementId: 'G-8NECV1E3F0',
        },
        'doctornow-web',
      );

      const analyticsConfig: AnalyticsSettings = {
        config: { send_page_view: false },
      };

      this._analytics = initializeAnalytics(webApp, analyticsConfig);
      this._analyticsLegacy = initializeAnalytics(doctornowApp, analyticsConfig);

      Airbridge.initialize();

      const environmentProperties = this.getUserServiceEnvironmentProperty(userAgent);

      this.setUserProperties(environmentProperties);
      this.setDefaultEventParameters(environmentProperties);
    } catch (error) {
      infoReport('Firebase:initApp > error', { error }, getErrorObject(error));
    }
  }

  static removeListener() {
    if (!this._unsubscribe) return;
    this._unsubscribe();
  }

  static logEvent(eventName: string, eventParams?: Record<string, unknown>) {
    try {
      if (!this._analytics || !this._analyticsLegacy) return;
      firebaseLogEvent(this._analytics, eventName, eventParams);
      firebaseLogEvent(this._analyticsLegacy, eventName, eventParams);
      Airbridge.trackEvent(eventName, eventParams);
    } catch (error) {
      infoReport(
        'Firebase:logEvent > error',
        {
          error,
          eventName,
          eventParams,
        },
        getErrorObject(error),
      );
    }
  }

  static setUserProperties(properties: Record<string, string>) {
    try {
      if (!this._analytics || !this._analyticsLegacy) return;
      firebaseSetUserProperties(this._analytics, { ...properties });
      firebaseSetUserProperties(this._analyticsLegacy, { ...properties });
      Airbridge.setUserProperties(properties);
    } catch (error) {
      infoReport('Firebase:setUserProperties > error', { error, properties }, getErrorObject(error));
    }
  }

  static setUserId(id: string) {
    try {
      if (!this._analytics || !this._analyticsLegacy) return;
      firebaseSetUserId(this._analytics, id);
      firebaseSetUserId(this._analyticsLegacy, id);
      Airbridge.setUserId(id);
    } catch (error) {
      infoReport('Firebase:setUserId > error', { error, userId: id }, getErrorObject(error));
    }
  }

  static setDefaultEventParameters(properties: Record<string, string>) {
    try {
      if (!this._analytics || !this._analyticsLegacy) return;
      setDefaultEventParameters({ ...properties });
    } catch (error) {
      infoReport('Firebase:setDefaultEventParameters > error', { error }, getErrorObject(error));
    }
  }

  static getMobileWebUserServiceEnvironmentProperty() {
    return {
      service_platform: SERVICE_PLATFORM.USER_WEB,
      service_ua_category: SERVICE_UA_CATEGORY.BROWSER,
      device_platform: DEVICE_PLATFORM.MOBILE_WEB,
      env: getEnv(),
    };
  }

  static getUserServiceEnvironmentProperty(userAgent: string) {
    if (isWebViewByUserAgent(userAgent)) {
      // 웹뷰
      return {
        service_platform: SERVICE_PLATFORM.USER_WEB,
        service_ua_category: SERVICE_UA_CATEGORY.DEVICE,
        device_platform: DEVICE_PLATFORM.WEB_VIEW,
        env: getEnv(),
      };
    }
    if (isMobileWebByUserAgent(userAgent)) {
      // 모바일 웹
      return {
        service_platform: SERVICE_PLATFORM.USER_WEB,
        service_ua_category: SERVICE_UA_CATEGORY.BROWSER,
        device_platform: DEVICE_PLATFORM.MOBILE_WEB,
        env: getEnv(),
      };
    }
    // 웹
    return {
      service_platform: SERVICE_PLATFORM.WEB,
      service_ua_category: SERVICE_UA_CATEGORY.BROWSER,
      device_platform: DEVICE_PLATFORM.PC_WEB,
      env: getEnv(),
    };
  }
}

export default Firebase;
