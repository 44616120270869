export const zIndex = {
  AppHeader: 260,
  MobileWebHeader: 310,
  Image: 2,
  Info: 5,
  Toast: 320,
  FloatingActionButton: 10,
  Dropbox: 10,
  BottomSticky: 50,
  TabSticky: 250,
  BreadCrumb: 100,
  PharmacyFilterWrapper: 102,
  PharmacyListBottomSheet: 3,
  PharmacyMapLoadingWrapper: 2,
};
