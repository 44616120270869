import React from 'react';

import MaterialIcon from '@/components/atom/MaterialIcon';
import Overlay from '@/components/molecules/Overlay';
import { CustomBackgroundColorModalProps } from '@/types/modal';

const CustomBackgroundColorModal = ({ body, onClickOverlay, backgroundColor }: CustomBackgroundColorModalProps) => {
  return (
    <Overlay index={10} onClickOverlay={onClickOverlay} backgroundColor={backgroundColor}>
      <button className="absolute right-8 top-8 text-white" type="button" onClick={onClickOverlay}>
        <MaterialIcon name="close" color="white" size={24} />
      </button>
      {body}
    </Overlay>
  );
};

export default CustomBackgroundColorModal;
